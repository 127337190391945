import React, { useEffect, useState } from "react";
import { keepTwoDecimalFull } from "@/utils/common";
import { getRealTimeInfo, getSpillway } from "@/api/union";
import Tit from "@/components/tit";
import Style from "./index.module.less";
const _ = require("lodash");
function Spillway(props) {
    const [spillway, setSpillway] = useState([]); // 溢洪道
    const [zjtInfo, setZjtInfo] = useState({}); // 钟家坛
    const [amount, setAmount] = useState(0); // 总量
    // /**
    //  * 钟家坛断面信息 
    //  */
    // const getRealTimeInfoFun = () => {
    //     getRealTimeInfo().then(res => {
    //         const results = res.data.results
    //         setZjtInfo(results)
    //     }).cacth(err => { })
    // }
    /**
     * 溢洪道信息
     */
    const getSpillwayFun = () => {
        getSpillway().then(res => {
            const results = res.data.results
            setAmount(_.sumBy(results[`jksk`], 'flow'))
            setSpillway(results[`jksk`])
        })
    }
    const allFun = async () => {
        await getSpillwayFun();
        // await getRealTimeInfoFun()
        setTimeout(() => {
            allFun()
        }, 300000); //   5分钟接口单独请求一次
    }
    useEffect(() => {
        allFun()
    }, [])
    return (
        <div className={`${Style[`union-floodWater-wrap`]} m-t-10`}>
            <Tit tit="溢洪道流量">
                <div className={`${Style[`tit`]}`}>
                    <span>
                        <i>{keepTwoDecimalFull(amount || 0, 1)}</i>
                    </span>
                    <span className={`${Style[`unit`]} m-l-5`}>m³/s</span>
                </div>
            </Tit>
            <div className={`${Style[`hs-floodWater-wrap-main`]} flex flex-a-c flex-colum`}>
                <div className={`${Style[`hs-zm`]}`}>
                    <ul className="flex">
                        {spillway.map((item, index) =>
                            <li key={index} className="flex flex-a-c flex-colum flex-j-c">
                                <div className="flex flex-a-c flex-j-c">
                                    <dt>{item.gateNo}#弧门</dt>
                                    <div>
                                        <dd>
                                            <span className={`${Style[`num`]}`}>
                                                <i>{keepTwoDecimalFull(item.flow || 0, 1)}</i>
                                            </span>
                                            <span className={`${Style[`unit`]}`}>m³/s</span>
                                        </dd>
                                        <dd>
                                            <span className={`${Style[`num`]}`}>
                                                <i>{keepTwoDecimalFull(item.open || 0, 2)}</i>
                                            </span>
                                            <span className={`${Style[`unit`]}`}>m</span>
                                        </dd>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div >
    );
}
export default Spillway;
